
























































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import { NotifyTemplateCreateOrUpdateDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class NotifyList extends Vue {
  tableItems: NotifyTemplateCreateOrUpdateDto[] = [];
  typeList: any = [];

  queryForm = {
    title: "",
    notifyTemplateType: null,
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.notifyTemplate.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "notifyCreate" });
  }

  // 查看详情
  jumpDetail(index: number, row: NotifyTemplateCreateOrUpdateDto) {
    this.$router.push({
      name: "notifyDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(index: number, row: NotifyTemplateCreateOrUpdateDto) {
    this.$router.push({
      name: "notifyEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(index: number, row: NotifyTemplateCreateOrUpdateDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.notifyTemplate.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
